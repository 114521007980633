@import 'assets/variables';

$footer-height: 40px;

.logo {
  height: 75px;
  width: 75px;
  pointer-events: none;
  padding-left: 16px;
}

.logo-large {
  height: 120px;
  width: 120px;
  pointer-events: none;
}

.logo-consent {
  height: 50px;
  width: 70px;
  pointer-events: none;
  vertical-align: middle;
}

.app-content-container {
  /* this is for footer to stay where it should be */
  position: relative;
  min-height: 100vh;
}

.app-content-wrapper {
  padding-bottom: $footer-height + 48px;
}

.header {
  background-color: $background-color;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: $title-font-family;
  font-size: 50px;
  color: white;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  color: gray;
  background-color: lightgrey;
  text-align: center;
  padding: 10px;
  box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.2);
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font-family;
  color: $title-color;
}

.selected-row {
  background-color: $selected-row-color;
}

.last-modified-info {
  color: $last-modified-color;
  font-size: 12px;
}

.hidden {
  visibility: hidden;
  display: none;
}

.pointer {
  cursor: pointer;
}

.warning-icon {
  color: $warning-color;
}

/* Tab panel colors */ 
.tab-panel {
  min-height: 600px;
  background-color: $tab-background-color;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  background-color: $tab-background-color;
}

.MuiTabs-flexContainer {
  background-color: white;
}

/* Tab panel colors END */ 

/* Form control colors */
.MuiInputBase-root {
   background-color: white;
}

.MuiInputLabel-root {
  color: rgba(0,0,0,0.34);
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  color:rgba(0,0,0,0.54);
}

/* Form control colors END */

.main-menu-badge {
  .MuiBadge-badge {
    color: $theme-main-color;
    background-color: $warning-color;
  }
}

.notification-item {
  background-color: $tab-background-color !important;
}

.concact-person-image {
  height: 110px;
  border-radius: 4px;
}

.danger {
  background-color: $danger-color !important;
  color: white;
}

.danger-btn {
  color: $danger-color !important;
}

a {
  text-decoration: none;
}

.preview-feature {
  width: 193px;
  height: 143px;
}

.sp-mobile-logo {
  height: 64px;
}

.store-badge {
  height: 40px;
}

.qr-code {
  height: 140px;
}

.concact-facebook-logo {
  height: 35px;
  vertical-align: middle;
}

.concact-youtube-logo {
  height: 25px;
  vertical-align: middle;
}

.accordion-item {
  background-color: $accordion-background-color !important;
  &:hover {
    background-color: $selected-row-color !important;
  }
}

.accordion-selected {
  background-color: $selected-row-color !important;
}

.error-border {
  border: $danger-color solid 3px;
}

.project {
	position: relative;
	width: 230px;
	height: 170px;
  background: transparent;
  pointer-events: none;
	overflow: hidden;
  margin-bottom: -172px;
  top: 0;
  left: 0;
  z-index: 99;
  .badge {
    &.yellow{
      background: $warning-color;
    }
    &.diagonal{
      white-space: nowrap;
      position: absolute;
      padding: 8px 100px;
      min-width: 230px;
      transform: rotate(-34deg) translate(-33%, 0);
      color: black;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      top: -20px;
      box-sizing: border-box;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)
    }
  }
}

.card-notice {
  background-color: $theme-secondary-color;
  h5 {
    color: white;
  }
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.card-notice-text {
  background-color: $tab-background-color;
  color: $danger-color;
}

.card-notice-button {
  color: $tab-background-color;
}

.church-name {
  opacity: 0.7;
  &.title {
    font-size: 18px;
    color: #AAAAAA
  }
  &.church {
    font-size: 19px;
    color: $theme-main-color;
    font-weight: 400;
  }
}

.archive-background {
  background-color: #674ea7;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.41' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.archive-background-light {
  border-radius: 0px 0px 6px 6px;
  background-color: #dfdbe5;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffff00' fill-opacity='0.22' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

a:-webkit-any-link.white-link {
  color: #ffffff;
  text-decoration: none;
}

.table-head-cell {
  background-color: $theme-main-color;
  color: white;
}

.main-menu-right-separator {
  border-right: 1px solid !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cookie_consent_btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: white;
    background-color: $theme-main-color;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.shk_logo {
  height: 81px;
  width: 149px;
  pointer-events: none;
}

.fullScreenActionBar {
  background-color:#d3d3d3;
}