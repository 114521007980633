//$highlight-color: #532B72
$theme-main-color: rgb(103, 78, 167);
$theme-secondary-color: #868FA0;
$background-color: $theme-main-color;
$title-color-inverse: #e6dff9;

$title-color: #281559;

$last-modified-color: #bdb4d6;

// $background-light-color: #518fd4;
// $background-light-color-hover: #619fe4;
// $background-dark-color: #093b64 !important;
// $background-dark-color-hover: #194b74;
// $background-form-blue: #e2edff;
// $background-form-blue-hover: #eff3ff;
// $background-form-blue-selected: #bfd6fc;
// $background-submenu-color: #597EAA;
// $background-form: #efefef;

$error-color: #bd4a33;

$normal-font-family: 'Roboto','Arial';
$title-font-family: 'Baloo 2','Verdana';

$selected-row-color: rgba(103,78,177,0.45); //#e0e0e0;
$table-header-color: $theme-main-color;
$tab-background-color: #fafafa;
$accordion-background-color: #dadada;

$danger-color: rgba(211, 47, 47, 0.70);

$warning-color: #ff9800; // #FFB818
